<template>
  <common-page-table
    v-if="reinit"
    :lockToken="lockToken"
    :token-icon="!isBlocked"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :apiDeleteName="''"
    :titleTable="$t('Токены')"
    :paramsGetData="paramsGetData"
    :fieldLand="fieldLang"
    :hasLang="false"
    :keyTable="'attrEntityId'"
    :force-delete="false"
    :forceIsCreate="false"
    :forceIsEdit="false"
    :force-is-show="false"
    :filterField="filterField"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
  >

    <RadioGroup v-model="radio1" size="small" @change="handleChange">
      <RadioButton :label="1">{{ $t('ActiveToken') }}</RadioButton>
      <RadioButton :label="2">{{ $t('LockedToken') }}</RadioButton>
    </RadioGroup>

  </common-page-table>
</template>
<script>
import { NAME_OBJECT, LIST_EQUIVALENT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import {API_METHODS, GLOBAL_DATE_TIME_FORMAT} from "../../../../constant";
import TheTable from "@/components/v2/commonElement/TheTable.vue";
import {ApiService} from "@/util/ApiService";
import {RadioButton, RadioGroup} from "element-ui";
import convertLangDataToTable from "@/helpers/converters/convertLangDataToTable";
import formatData from "@/helpers/converters/formatData";
export default {
  components: {RadioGroup, RadioButton, TheTable, CommonPageTable },
  data() {
    return {
      reinit: true,
      radio1:1,
      fieldLang: '',
      apiName: API_METHODS.GET_TOKENS,
      isBlocked: false
    };
  },
  computed: {
    paramsGetData() {
      return {  id: this.$route.params['AttrEntityId'], dopParams:{  forUserId: this.$route.params['UserId'], isBlocked:this.isBlocked}  };
    },
    nameRoleEntity() {
      return NAME_OBJECT.attrEntity
    },
  },
  methods: {
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, "", false);
        tempdataNow = tempdataNow.map(item => {
          return { ...item ,
            dateCreated:
              this.$moment(item.dateCreated + ' +03:00', "DD-MM-YYYY HH:mm:ss ZZ")
                .local()
                .format(
                  GLOBAL_DATE_TIME_FORMAT
                ) ,
            dateExpires:
              this.$moment(item.
                dateExpires  + ' +03:00', "DD-MM-YYYY HH:mm:ss ZZ")
                .local()
                .format(
                  GLOBAL_DATE_TIME_FORMAT
                )
          };
        });
        return tempdataNow;
      }

      return [];
    },

    handleChange(val) {
      this.isBlocked = val === 2;
      this.reinit = false;
      this.$nextTick(() => {
        this.reinit = true;
      })
    },
    filterField(fields) {
      const noFieldCode = ["forUserName","byUserFullName","byUserEmail","hrFeedUserTokenId", "forUserId","byUserId","blockedByUserId"];
      if(!this.isBlocked) {
        noFieldCode.push("dateBlocked");
        noFieldCode.push("blockingMethod");
        noFieldCode.push("blockedByUserName");
      }



      return fields.filter(item => !noFieldCode.includes(item.code))
    },
    async  lockToken(row){
      const paramsIn = {
        self: this,
        token: row.hrFeedUserTokenId
      };
      await ApiService(API_METHODS.LOCK_TOKEN, {
        ...paramsIn
      });
    },
  }
};
</script>
